import rolesList from '../../../components/Pages/Well/rolesList'
import { STATUS } from '../../../constants'
const { ESCALATED } = STATUS

export const getQueryByMyRolesWithExclusions = (currentUserRoles: string[], status) => {
  // WPA_DATA_SCIENTIST AND WPA_TRIAL_USERS don't make available to each other
  let excludedList = []
  if (
    currentUserRoles.includes('WPA_DATA_SCIENTIST') ||
    currentUserRoles.includes('DATA SCIENTIST')
  ) {
    excludedList = ['WPA_TRIAL_USERS', 'TRIAL USER']
  } else if (
    currentUserRoles.includes('WPA_TRIAL_USERS') ||
    currentUserRoles.includes('TRIAL USER')
  ) {
    excludedList = ['WPA_DATA_SCIENTIST', 'DATA SCIENTIST']
  } else {
    excludedList = ['WPA_TRIAL_USERS', 'TRIAL USER', 'WPA_DATA_SCIENTIST', 'DATA SCIENTIST']
  }

  if (excludedList.length === 0) return null

  const getCondition = () => {
    const matchedRolesConditions = excludedList.map(mr => {
      return {
        match: {
          role: mr,
        },
      }
    })

    const cond = {
      bool: {
        must_not: matchedRolesConditions,
      },
    }

    return cond
  }
  const getConditionWhenStatusIsEscalated = () => {
    const matchedRolesConditions = excludedList.map(mr => {
      return {
        match: {
          escalatedByRoles: mr,
        },
      }
    })

    const cond = {
      bool: {
        must_not: matchedRolesConditions,
      },
    }

    return cond
  }

  const cond =
    status?.toLowerCase() === ESCALATED.toLowerCase()
      ? getConditionWhenStatusIsEscalated()
      : getCondition()

  return {
    bool: {
      should: [cond],
    },
  }
}

export const getQueryByMyRoles = (
  currentUserEmail: string,
  currentUserRoles: string[],
  isDashboard,
  status
) => {
  let matchedRoles = rolesList
    .filter(
      rolesListItem =>
        currentUserRoles.includes(rolesListItem.value) ||
        currentUserRoles.includes(rolesListItem.label)
    )
    .reduce((acc, curr) => {
      return [...acc, curr.label, curr.value]
    }, [])

  if (isDashboard) {
    //const excludedList = ['WPA_TRIAL_USERS', 'TRIAL USER', 'WPA_DATA_SCIENTIST', 'DATA SCIENTIST']

    let excludedList = []
    if (
      (currentUserRoles.includes('WPA_DATA_SCIENTIST') ||
        currentUserRoles.includes('DATA SCIENTIST')) &&
      currentUserRoles.length === 1
    ) {
      excludedList = ['WPA_TRIAL_USERS', 'TRIAL USER']
    } else if (
      (currentUserRoles.includes('WPA_TRIAL_USERS') || currentUserRoles.includes('TRIAL USER')) &&
      currentUserRoles.length === 1
    ) {
      excludedList = ['WPA_DATA_SCIENTIST', 'DATA SCIENTIST']
    } else {
      excludedList = ['WPA_TRIAL_USERS', 'TRIAL USER', 'WPA_DATA_SCIENTIST', 'DATA SCIENTIST']
    }

    matchedRoles = matchedRoles.filter(mr => !excludedList.includes(mr))
  }

  //match roles
  const getCondition = () => {
    const matchedRolesConditions = matchedRoles.map(mr => {
      return {
        match: {
          role: mr,
        },
      }
    })

    const cond = {
      bool: {
        should: matchedRolesConditions,
      },
    }

    return cond
  }

  const getConditionWhenStatusIsEscalated = () => {
    const matchedRolesConditions = matchedRoles.map(mr => {
      return {
        match: {
          escalatedByRoles: mr,
        },
      }
    })

    const cond = {
      bool: {
        should: matchedRolesConditions,
      },
    }

    return cond
  }

  const cond =
    status?.toLowerCase() === ESCALATED.toLowerCase()
      ? getConditionWhenStatusIsEscalated()
      : getCondition()

  return {
    bool: {
      should: [cond],
    },
  }
}
