import { getQueryByGlobalFilters } from './getQueryByGlobalFilters'
import { getQueryByLocalFilters } from './getQueryByLocalFilters'
import { getQueryByMyRoles, getQueryByMyRolesWithExclusions } from './getQueryByMyRoles'
import { getQueryBySearchText } from './getQueryBySearchText'
import { getQueryByWellId } from './getQueryByWellId'
import { getQueryByStatus } from './getQueryByStatus'
import { getQueryByCompletedAt } from './getQueryByCompletedAt'
import { getQueryByCompletedBy } from './getQueryByCompletedBy'
import { getQueryByUpdatedAt } from './getQueryByUpdatedAt'
import { getQueryByUpdatedBy } from './getQueryByUpdatedBy'
import { getQueryByStatuses } from './getQueryByStatuses'

export const getQueryByAllFilters = params => {
  const {
    statuses,
    status,
    wellId,
    searchText,
    localFilters,
    globalFilters,
    tasksUseMyRolesToggleValue,
    currentUserEmail,
    currentUserRoles,
    updatedAt = null,
    updatedBy = null,
    completedBy = null,
    completedAt = null,
    isDashboard = null,
  } = params

  const allExpressions: any[] = []

  if (status && statuses) {
    throw new Error('status and statuses can not be passed together!')
  }

  if (status) {
    const queryByStatus = getQueryByStatus(status, currentUserEmail)
    allExpressions.push(queryByStatus)
  }

  if (statuses) {
    const queryByStatuses = getQueryByStatuses(statuses, currentUserEmail)
    allExpressions.push(queryByStatuses)
  }

  if (wellId) {
    const queryByWellId = getQueryByWellId(wellId)
    allExpressions.push(queryByWellId)
  }
  if (searchText) {
    const queryBySearchText = getQueryBySearchText(searchText)
    allExpressions.push(queryBySearchText)
  }
  if (localFilters && localFilters.filter(lf => lf.length > 0).length > 0) {
    const queryByLocalFilters = getQueryByLocalFilters(localFilters, wellId)
    allExpressions.push(queryByLocalFilters)
  }

  if (globalFilters) {
    const queryByGlobalFilters = getQueryByGlobalFilters(globalFilters)
    if (queryByGlobalFilters) {
      allExpressions.push(queryByGlobalFilters)
    }
  }

  // if(tasksUseMyRolesToggleValue) {
  //   const queryByMyRoles = getQueryByMyRoles(currentUserEmail, currentUserRoles)
  //   allExpressions.push(queryByMyRoles)
  // } else {
  //   const queryByMyRolesExcl = getQueryByMyRolesWithExclusions(currentUserRoles)

  //   if (queryByMyRolesExcl) {
  //     allExpressions.push(queryByMyRolesExcl)
  //   }
  // }

  if (tasksUseMyRolesToggleValue) {
    const queryByMyRoles = getQueryByMyRoles(
      currentUserEmail,
      currentUserRoles,
      isDashboard,
      status
    )
    allExpressions.push(queryByMyRoles)
  } else {
    if (!isDashboard) {
      const queryByMyRolesExcl = getQueryByMyRolesWithExclusions(currentUserRoles, status)

      if (queryByMyRolesExcl) {
        allExpressions.push(queryByMyRolesExcl)
      }
    }
  }

  if (isDashboard) {
    const queryByMyRolesExcl = getQueryByMyRolesWithExclusions(currentUserRoles, status)

    if (queryByMyRolesExcl) {
      allExpressions.push(queryByMyRolesExcl)
    }
  }

  if (updatedAt && updatedAt?.from && updatedAt?.to) {
    const queryByUpdatedAt = getQueryByUpdatedAt(updatedAt)
    allExpressions.push(queryByUpdatedAt)
  }
  if (updatedBy) {
    const queryByUpdatedBy = getQueryByUpdatedBy(updatedBy)
    allExpressions.push(queryByUpdatedBy)
  }
  if (completedBy) {
    const queryByCompletedBy = getQueryByCompletedBy(completedBy)
    allExpressions.push(queryByCompletedBy)
  }
  if (completedAt && completedAt?.from && completedAt?.to) {
    const queryByCompletedAt = getQueryByCompletedAt(completedAt)
    allExpressions.push(queryByCompletedAt)
  }

  return {
    bool: {
      must: allExpressions,
    },
  }
}
